import store from '@/store/index'
import useJwt from '@/cubus/jwt/useJwt'
import jwtDefaultConfig from '@/cubus/jwt/jwtDefaultConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import JSZip from 'jszip'

export default class CubusService {
  release() {
    return '1.0.8.161.240910.01'
  }

  getSession() {
    const isSession = store.getters['cubus-store/IS_SESSION']
    console.log()
    if (!isSession) {
      const token = localStorage.getItem(jwtDefaultConfig.storageCubusTokenKeyName)
      if (token) {
        useJwt.whoami({ token })
          .then(response => {
            console.log('whoami response', response)
            if (response.data.session) {
              store.commit('cubus-store/SET_SESSION', response.data.session)
              this.$i18n.locale = response.data.session.user.languagesid
            } else if (response.data.error) {
              console.log('whoami response error', response.data.error)
              // this.logout(token)

              // Redirect to login page
              // this.$router.push({ name: 'auth-login' })
            }
            return store.getters['cubus-store/SESSION']
          })
          .catch(error => {
            console.log('whoami error', error)
          })
      }
    }
    return store.getters['cubus-store/SESSION']
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    console.log('cubus.logout start')
    const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
    if (token) useJwt.logout({ token })
    store.commit('cubus-store/CLEAR_SESSION')
    localStorage.removeItem(useJwt.jwtConfig.storageCubusTokenKeyName)
  }

  toastError(vm, msg) {
    vm.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Something went wrong',
        icon: 'CoffeeIcon',
        variant: 'warning',
        text: msg,
      },
    })
    if (msg === 'Session not found.') {
      this.logout()
      this.pushLogin(vm)
    }
  }

  toastMessage(vm, msg) {
    vm.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Message',
        icon: 'CoffeeIcon',
        variant: 'info',
        text: msg,
      },
    })
    if (msg === 'Session not found.') {
      this.logout()
      this.pushLogin(vm)
    }
  }

  processLogin(vm, response) {
    useJwt.setCubusToken(response.data.session.token)
    vm.$store.commit('cubus-store/SET_SESSION', response.data.session)

    // const { userData } = response.data
    // useJwt.setToken(response.data.accessToken)
    // useJwt.setRefreshToken(response.data.refreshToken)
    // localStorage.setItem('userData', JSON.stringify(userData))
    // vm.$ability.update([{ action: 'manage', subject: 'all' }])

    // ? This is just for demo purpose as well.
    // ? Because we are showing eCommerce app's cart items count in navbar
    // vm.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', { eCommerceCartItemsCount: 5 })

    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
    vm.$router.replace('/')
      .then(() => {
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Welcome!',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `You have successfully logged in as ${response.data.session.user.role.name}. Now you can start to explore!`,
          },
        })
      })
  }

  isSession() {
    return store.getters['cubus-store/IS_SESSION']
  }

  setSession(sess) {
    store.commit('cubus-store/SET_SESSION', sess)
    this.$i18n.locale = sess.user.languagesid
  }

  whoami() {
    const status = store.getters['cubus-store/SESSION_STATUS']
    console.log('status', status)
    if (status === 'init') {
      const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
      if (token) {
        store.commit('cubus-store/SET_SESSION_STATUS', 'loading')
        this.loadWhoami()
      }
    }
    return {
      status: store.getters['cubus-store/SESSION_STATUS'],
      session: store.getters['cubus-store/SESSION'],
      error: store.getters['cubus-store/SESSION_ERROR'],
    }
  }

  loadWhoami() {
    console.log('start loadWhoami')
    const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
    useJwt.whoami({ token })
      .then(response => {
        console.log('whoami response', response)
        if (response.data.session) {
          store.commit('cubus-store/SET_SESSION', response.data.session)
        } else if (response.data.error) {
          store.commit('cubus-store/SET_SESSION_ERROR', response.data.error)
          if (response.data.error === 'Session not found.') {
            this.logout()
          }
        } else {
          store.commit('cubus-store/SET_SESSION_ERROR', 'no_session')
        }
      })
      .catch(error => {
        console.log('whoami error')
        store.commit('cubus-store/SET_SESSION_ERROR', error)
      })
  }

  loadWhoamiVm(vm) {
    const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
    if (token) {
      useJwt.whoami({ token })
        .then(response => {
          console.log('whoami response', response)
          if (response.data.error) {
            this.toastError(vm, response.data.error)
          } else if (response.data.session) {
            vm.session = response.data.session
          }
        })
        .catch(error => {
          this.toastError(vm, error)
        })
    } else {
      this.pushLogin(vm)
    }
  }

  isAdmin() {
    const sess = store.getters['cubus-store/SESSION']
    // console.log('sess', sess)
    return sess && sess.user
      && (
        (sess.user.sid && (sess.user.sid === 'root' || sess.user.sid === 'batman'))
        || (sess.user.roles && sess.user.roles.some(r => r.sid && r.sid === 'admin'))
      )
  }

  isDeveloper() {
    const sess = store.getters['cubus-store/SESSION']
    // console.log('sess for isDev', sess)
    return sess && sess.user
      && (
        (sess.user.sid && (sess.user.sid === 'root' || sess.user.sid === 'batman'))
        || (sess.user.roles && sess.user.roles.some(r => r.sid && r.sid === 'dev'))
      )
  }

  userLocale() {
    const sess = store.getters['cubus-store/SESSION']
    if (sess) {
      return sess.user.languagesid
    }
    return 'en'
  }

  loadObjectBySid(vm, param) {
    /* const defaultParam = {
      objectsid: 'mount',
      domainsid: '',
    } */
    useJwt.query({
      query: {
        method: 'objectbysid',
        param,
      },
    })
      .then(response => {
        console.log('object response', response)
        if (response.data.thread) {
          this.delayObjectBySid(response.data.thread, vm)
        } else if (response.data.error) {
          this.toastError(vm, response.data.error)
        }
      })
      .catch(error => {
        console.log('object error', error)
      })
  }

  delayObjectBySid(threadName, vm) {
    setTimeout(() => this.threadObjectBySid(threadName, vm), 500)
  }

  threadObjectBySid(threadName, vm) {
    useJwt.query({
      query: {
        method: 'thread',
        param: {
          threadname: threadName,
        },
      },
    })
      .then(response => {
        console.log('thread response', response)
        if (response.data.thread) {
          if (response.data.thread.error) {
            this.toastError(vm, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            console.log('threadObjectBySid', response)
            vm.object = response.data.thread.result
          } else {
            this.delayObjectBySid(threadName, vm)
          }
        }
        if (response.data && response.data.error) {
          this.toastError(vm, response.data.error)
        }
      })
      .catch(error => {
        console.log('thread error', error)
      })
  }

  pushLogin(vm) {
    vm.$router.push({ name: 'auth-login' })
  }

  filterBy(option, label, search) {
    const pattern = `.*${search
      .replace(new RegExp(' ', 'gi'), '.*')
      .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
    const re = new RegExp(pattern, 'gi')
    return re.test(label || '')
  }

  addThousandSeparator(integer, separator) {
    return integer.toString().replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
  }

  defaultFormatOptions = {
    prefix: '',
    suffix: '',
    decimal: ',',
    thousand: '.',
    precision: 2,
    acceptNegative: true,
    isInteger: false,
  }

  formatNumber(input, opt) {
    if (input === null) input = 0
    const mergedOptions = { ...this.defaultFormatOptions, ...opt }
    if (typeof input === 'number' && !mergedOptions.isInteger) {
      input = input.toFixed(this.fixed(mergedOptions.precision))
    }
    const negative = this.isNegative(input, mergedOptions.acceptNegative) ? '-' : ''
    const numbers = this.onlyNumbers(input)
    const currency = this.numbersToCurrency(numbers, mergedOptions.precision)
    const parts = this.toStr(currency).split('.')
    let integer = parts[0]
    const decimal = parts[1]
    integer = this.addThousandSeparator(integer, mergedOptions.thousand)
    return negative + mergedOptions.prefix + this.joinIntegerAndDecimal(integer, decimal, mergedOptions.decimal) + mergedOptions.suffix
  }

  formatPercent(val) {
    return `${(parseFloat(val) * 100).toFixed(2)}%`
  }

  isNegative(string, acceptNegative = true) {
    if (!acceptNegative) return false
    if (typeof string !== 'string') string = string.toString()
    const forcePositive = string.indexOf('+') >= 0
    const isNegative = !!((string !== 0 && string.indexOf('-') >= 0 || string[string.length - 1] == '-'))
    return !!((!forcePositive && isNegative))
  }

  onlyNumbers(input) {
    return this.toStr(input).replace(/\D+/g, '') || '0'
  }

  toStr(value) {
    return value ? value.toString() : ''
  }

  numbersToCurrency(numbers, precision) {
    const exp = Math.pow(10, precision)
    const float = parseFloat(numbers) / exp
    return float.toFixed(this.fixed(precision))
  }

  fixed(precision) {
    return Math.max(0, Math.min(precision, 20))
  }

  joinIntegerAndDecimal(integer, decimal, separator) {
    return decimal ? integer + separator + decimal : integer
  }

  unformatNumber(input, opt = { precision: 2, isInteger: false, acceptNegative: true }) {
    if (input === null) input = 0
    const mergedOptions = { ...defaultOptions, ...opt }
    const negative = (isNegative(input, mergedOptions.acceptNegative)) ? -1 : 1
    const numbers = onlyNumbers(input)
    const currency = numbersToCurrency(numbers, mergedOptions.precision)
    if (mergedOptions.isInteger) {
      return parseInt(`${isNegative(input, mergedOptions.acceptNegative) ? '-' : ''}${numbers.toString()}`)
    }
    return parseFloat(currency) * negative
  }

  randomString() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  }

  guid() {
    // return this.randomString() + this.randomString() + '-' + this.randomString() + '-' + this.randomString() + '-' + this.randomString() + '-' + this.randomString() + this.randomString() + this.randomString()
    return `${this.randomString()}${this.randomString()}-${this.randomString()}-${this.randomString()}-${this.randomString()}-${this.randomString()}${this.randomString()}${this.randomString()}`
  }

  setPageTitle(title) {
    // document.title = title ? `Cubus﹒${title}` : 'Cubus﹒Business Automation Platform'
    document.title = title || 'Cubus﹒Business Automation Platform'
  }

  attributeToField(attr) {
    return {
      title: attr.label,
      value: attr.entityid,
      datatype: attr.datatype,
      attributeid: attr.attributeid,
      entitysid: attr.entitysid,
      parententityid: attr.parententityid,
      refobjectid: attr.refobjectid,
    }
  }

  locale(loc) {
    if (loc === 'ru') return 'ru-RU'
    return 'en-US'
  }

  isCredentialAvailable(access, permissiontype) {
    return access && access[permissiontype]
      && (access[permissiontype] === 'grant' || access[permissiontype] === 'withgrant')
  }

  async zipFiles(files) {
    const zip = new JSZip()
    // Добавляем каждый выбранный файл в архив
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      // eslint-disable-next-line no-await-in-loop
      const fileContent = await this.readFileContent(file)
      zip.file(file.name, fileContent, { compression: 'DEFLATE' }) // Указываем сжатие для файла
    }
    const zipBlob = await zip.generateAsync({ type: 'blob' })
    return zipBlob
  }

  async readFileContent(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
      reader.readAsArrayBuffer(file)
    })
  }
}
